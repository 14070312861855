import * as React from 'react';
import CertificatesAndPartners from 'src/components/widgets/CertificatesAndPartners/CertificatesAndPartners';
import Footer from 'src/components/widgets/Footer/Footer';
import OurTeam from 'src/components/widgets/OurTeam/OurTeam';
import Statement from 'src/components/widgets/Statement/Statement';
import statementData from 'src/data/statementDate/statementDate';
import SliderPage from '../../widgets/SliderPage/SliderPage';
import './AboutCompany.scss';

interface IAboutCompanyState {
    classColorDots: string;
}

export default class AboutCompany extends React.PureComponent<any, IAboutCompanyState> {
    public render() {
        const statementProps = statementData.AboutCompany;
        return (
            <div className={`main-page`}>
                <SliderPage>
                    <Statement {...statementProps} />
                    <OurTeam />
                    <CertificatesAndPartners />
                    <Footer />
                </SliderPage>
            </div>
        );
    }
}