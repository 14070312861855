import * as React from "react";
import './OurTeam.scss';

export default class OurTeam extends React.Component {
    public render() {
        return (
            <section className="our-team">
                <h2 className="our-team__title">Наша команда</h2>
                <ul className="our-team__list list">
                    <li className="our-team__item">Совместное решение задачи</li>
                    <li className="our-team__item">Коллективная ответственность за результат перед заказчиком</li>
                    <li className="our-team__item">Индивидуальная ответственность за свой участок работы</li>
                    <li className="our-team__item">Обмен знаниями и опытом</li>
                    <li className="our-team__item">Психологический комфорт</li>
                </ul>
                <p className="our-team__text">Совместная работа разработчиков и технического отдела обеспечивает качественную работу 
                и высокопрофессиональный сервис. </p>
            </section>
        );
    }
}