import * as React from 'react';
import './MonitoringServices.scss';

export default class MonitoringServices extends React.Component {
    public render() {
        return (
            <section className="monitoring-services">
                <h2 className="monitoring-services__title">Сервисы GPS/ГЛОНАСС мониторинга</h2>
                <p className="monitoring-services__text">Система включает независимые друг от друга облачные сервисы (SaaS)*:</p>
                <ul className="monitoring-services__list">
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/1.jpg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">Транспорт.Онлайн</h3>
                        <p className="monitoring-services__list-item-text">Сервис слежения за коммерческим и личным транспортом с 
                        предоставлением отчетов в реальном времени.</p>
                    </li>
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/2.jpg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">Вистар-маршрут</h3>
                        <p className="monitoring-services__list-item-text">Расчет времени прибытия транспорта на остановку, 
                        равномерное распределение общественного транспорта на маршруте.</p>
                    </li>
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/3.jpg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">VistarPassenger</h3>
                        <p className="monitoring-services__list-item-text">Информация для пассажиров о маршруте, местоположении, 
                        прогнозном времени прихода общественного транспорта на остановку. Для личного использования, бесплатно.</p>
                    </li>
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/4.svg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">Вистар.Остановки</h3>
                        <p className="monitoring-services__list-item-text">Сервис показа на карте  расположения городского транспорта и времени его прибытия на остановку.</p>
                    </li>
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/5.jpg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">Кнопка SOS</h3>
                        <p className="monitoring-services__list-item-text">Контроль местонахождения человека, 
                        тревожная кнопка вызова помощи. Для личного использования, бесплатно.</p>
                    </li>
                    <li className="monitoring-services__list-item">
                        <div className="monitoring-services__list-img-wrap">
                            <img src="/img/monitoring-services/6.jpg" className="monitoring-services__list-img" />
                        </div>
                        <h3 className="monitoring-services__list-item-title">Трясометр</h3>
                        <p className="monitoring-services__list-item-text">Контроль качества дорожного покрытия с указанием на карте проблемных участков и ям.</p>
                    </li>
                </ul>
                <span className="monitoring-services__explanation">*Сервисы доступны с любого устройства, подключенного к Интернету.</span>
            </section>
        );
    }
}