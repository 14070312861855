import * as React from 'react';
import Slider from 'react-slick';
import './OurAdvantages.scss';

export default class OurAdvantages extends React.Component {
    public render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dotsClass: 'our-advantages-dots'
        };
        return (
            <section className="our-advantages">
                <div className="our-advantages__wrap">
                    <h2 className="our-advantages__title">Почему мы</h2>
                    <div className="our-advantages__slider">
                        <Slider {...settings}>
                            <div>
                                <div className="our-advantages__slider-item">
                                    <div className="our-advantages__slider-content">
                                        <h3 className="our-advantages__slider-content-title">Надёжность системы</h3>
                                        <p className="our-advantages__slider-content-text">Наши контроллеры оснащены специальным защитным оборудованием и 
                                        мощным резервным аккумулятором, что гарантирует надежную работу системы в случае сбоя питания. 
                                        Все события регистрируются в специальных журналах. Все транспортные средства, подключенные 
                                        к системе спутникового мониторинга транспорта, гарантированно находятся под 24-часовым контролем.</p>
                                    </div>
                                    <div className="our-advantages__slider-img-wrap">
                                        <img src="/img/our-advantages/1.jpg" className="our-advantages__slider-img" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="our-advantages__slider-item">
                                    <div className="our-advantages__slider-content">
                                        <h3 className="our-advantages__slider-content-title">Опыт работы более 10 лет</h3>
                                        <p className="our-advantages__slider-content-text">Мы имеем многолетний опыт внедрения и 
                                        эксплуатации GPS/ГЛОНАСС систем слежения. Наше предприятие предлагает очень конкурентоспособные цены и 
                                        обеспечивает низкие эксплуатационные расходы системы. Расширенные возможности системы 
                                        обеспечивают средства для оказания помощи в управлении малого, среднего и крупного автопарка. </p>
                                    </div>
                                    <div className="our-advantages__slider-content-img-wrap">
                                        <img src="/img/our-advantages/2.jpg" className="our-advantages__slider-content-img" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="our-advantages__slider-item">
                                    <div className="our-advantages__slider-content">
                                        <h3 className="our-advantages__slider-content-title">Техническая поддержка 24/7</h3>
                                        <p className="our-advantages__slider-content-text">Мы оказываем круглосуточную помощь и 
                                        поддержку всем пользователям. Техподдержка осуществляется через систему заявок на корпоративном Портале. 
                                        Специалисты рассматривают все заявки от клиентов и 
                                        партнеров и оперативно предлагают оптимальное решение. </p>
                                    </div>
                                    <div className="our-advantages__slider-content-img-wrap">
                                        <img src="/img/our-advantages/3.jpg" className="our-advantages__slider-content-img" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="our-advantages__slider-item">
                                    <div className="our-advantages__slider-content">
                                        <h3 className="our-advantages__slider-content-title">Удобный интерфейс программы</h3>
                                        <p className="our-advantages__slider-content-text">Наша программа предоставляет 
                                        подробные и гибко настраиваемые отчеты - групповые, индивидуальные, универсальные или 
                                        разработанные под определенного заказчика. Все наши отчеты детализированные и имеют привязку событий к 
                                        электронной карте. Отчеты можно распечатать, 
                                        отправить по электронной почте или сохранить практически в любом формате.</p>
                                    </div>
                                    <div className="our-advantages__slider-content-img-wrap">
                                        <img src="/img/our-advantages/4.jpg" className="our-advantages__slider-content-img" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <h3 className="our-advantages__list-title">Пять главных причин выбрать наше решение для мониторинга</h3>
                    <ol className="our-advantages__list">
                        <li className="our-advantages__list-item">
                            Гарантия на оборудование <b>1 год</b>
                        </li>
                        <li className="our-advantages__list-item">
                            Сервисная поддержка <b>24/7</b>
                        </li>
                        <li className="our-advantages__list-item">
                            Конфиденциальность информации <b>100%</b>
                        </li>
                        <li className="our-advantages__list-item">
                            Карты <br /> 
                            из более чем <b>10 источников</b>
                        </li>
                        <li className="our-advantages__list-item">
                            На рынке мониторинга <b>с 2009 года</b>
                        </li>
                    </ol>
                </div>
            </section>
        );
    }
}