import * as React from "react";
import "./BenefitsGPS.scss";

export default class BenefitsGPS extends React.Component {
    public render() {
        return (
            <div className="benefits-gps">
                <div className="benefits-gps__wrap">
                        <h2 className="benefits-gps__title">Эффекты применения системы GPS/ГЛОНАСС контроля</h2>
                        <ul className="benefits-gps__list">
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/1.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Оптимизация маршрутов</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/2.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Эффективное использование техники и топлива</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/3.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Предотвращение хищения топлива</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/4.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Обеспечение безопасности персонала</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/5.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Защита транспорта от угона</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/6.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Увеличение срока эксплуатации техники</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/7.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Снижение аварийности</p>
                            </li>
                            <li className="benefits-gps__list-item">
                                <div className="benefits-gps__list-img-wrap">
                                    <img src="/img/benefits-gps/8.jpg" className="benefits-gps__list-img" />
                                </div>
                                <p className="benefits-gps__list-item-text">Укрепление дисциплины труда</p>
                            </li>
                        </ul>
                    </div>
                </div>
        );
    }
}