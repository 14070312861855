import * as React from 'react';
import BenefitsOfMonitoringServices from 'src/components/widgets/BenefitsOfMonitoringServices/BenefitsOfMonitoringServices';
import CertificatesAndPartners from 'src/components/widgets/CertificatesAndPartners/CertificatesAndPartners';
import Footer from 'src/components/widgets/Footer/Footer';
import OurAdvantages from 'src/components/widgets/OurAdvantages/OurAdvantages';
import WorkWithTheSystem from 'src/components/widgets/WorkWithTheSystem/WorkWithTheSystem';
import statementData from 'src/data/statementDate/statementDate';
import FeaturesAndBenefitsOfGPS from '../../widgets/FeaturesAndBenefitsOfGPS/FeaturesAndBenefitsOfGPS';
import MonitoringServices from '../../widgets/MonitoringServices/MonitoringServices';
import SliderPage from '../../widgets/SliderPage/SliderPage';
import Statement from '../../widgets/Statement/Statement';
import './MainPage.scss';

interface IMainPageState {
    classColorDots: string;
}

export default class MainPage extends React.PureComponent<any, IMainPageState> {
    public render() {
        const statementProps = statementData.MainPage;
        return (
            <div className={`main-page`}>
                <SliderPage>
                    <Statement {...statementProps}/>
                    <MonitoringServices />
                    <FeaturesAndBenefitsOfGPS />
                    <BenefitsOfMonitoringServices />
                    <WorkWithTheSystem />
                    <OurAdvantages />
                    <CertificatesAndPartners />
                    <Footer />
                </SliderPage>
            </div>
        );
    }
}