import * as React from "react";
import './MonitoringSystemInstallationTime.scss';

export default class MonitoringSystemInstallationTime extends React.Component {
    public render() {
        return (
            <section className="monitoring-system-installation-time">
                <h2 className="monitoring-system-installation-time__title">Срок установки системы мониторинга транспорта</h2>
                <p className="monitoring-system-installation-time__subtext">
                С момента оплаты – 2-3 дня по городу Воронежу и ближайшим регионам.
                    <b>Срочная установка по городу – в день обращения!</b>
                </p>
                <p className="monitoring-system-installation-time__list-title">Что для этого нужно:</p>
                <ul className="monitoring-system-installation-time__list list">
                    <li className="monitoring-system-installation-time__item">Ваша заявка в первой половине дня;</li>
                    <li className="monitoring-system-installation-time__item">Договор и оплата;</li>
                    <li className="monitoring-system-installation-time__item">Наличие на складе<br />необходимого количества оборудования.</li>
                </ul>
                <p className="monitoring-system-installation-time__text">У нас есть собственный склад – оборудование 
                для мониторинга автотранспорта всегда в наличии. 
                Для периферийных устройств, комплектующих может потребоваться доставка – 2-3 дня после оплаты.</p>
            </section>
        );
    }
}