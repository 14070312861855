import * as React from 'react';
import './MonitoringCapabilities.scss';

export default class MonitoringCapabilities extends React.Component {
    public render() {
        return (
                <div className="monitoring-сapabilities">
                    <div className="monitoring-сapabilities__wrap">
                        <h2 className="monitoring-сapabilities__title">Возможности GPS/ГЛОНАСС мониторинга для бизнеса</h2>
                        <ul className="monitoring-сapabilities__list">
                            <li className="monitoring-сapabilities__list-item">Контроль подвижных и неподвижных объектов</li>
                            <li className="monitoring-сapabilities__list-item">Реализация: SaaS или сервер заказчика</li>
                            <li className="monitoring-сapabilities__list-item">Отслеживание событий и состояния объекта </li>
                            <li className="monitoring-сapabilities__list-item">Доступ к данным — в реальном времени и за прошедший период</li>
                            <li className="monitoring-сapabilities__list-item">Оповещения о событиях по каналам</li>
                            <li className="monitoring-сapabilities__list-item">Удобные настраиваемые отчёты. Экспорт отчётов в разные форматы</li>
                            <li className="monitoring-сapabilities__list-item">Надёжный сервер для мониторинга любого количества объектов</li>
                            <li className="monitoring-сapabilities__list-item">Сертифицированное оборудование</li>
                        </ul>
                    </div>
                </div>
        );
    }
}