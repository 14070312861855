import * as React from 'react';
import './WorkWithTheSystem.scss';

export default class WorkWithTheSystem extends React.Component {
    public render() {
        return (
            <section className="work-with-the-system">
                <div className="work-with-the-system__wrap">
                    <h2 className="work-with-the-system__title">Как начать работать с системой</h2>
                    <ul className="work-with-the-system__list">
                        <li className="work-with-the-system__item">На транспортное средство устанавливается терминал (трекер) для ГЛОНАСС мониторинга. 
                        При необходимости к нему можно подключить как родные, 
                        так внешние датчики для измерения дополнительных параметров 
                        (датчик топлива, датчик работы механизмов, датчик моточасов и т.д.);</li>
                        <li className="work-with-the-system__item">Устанавливаются ГЛОНАСС и GSМ антенны. 
                        Для более стабильной работы ГЛОНАСС антенну рекомендуется выводить на крышу, либо на место имеющее максимальную видимость небосвода.
                        Местоположение GSМ антенны не имеет ограничений и рекомендаций по расположению;</li>
                        <li className="work-with-the-system__item">Данные со спутников поступают на ГЛОНАСС трекер, 
                        который свою очередь опрашивает датчики и передает данные на 
                        сервер спутникового мониторинга по каналу мобильной связи;</li>
                        <li className="work-with-the-system__item">Программный комплекс Vialon получает сообщения, фильтрует ложные (в случае, если такие имеются) и 
                        передает готовые значения на рабочее место диспетчера (либо любого другого ответственного за навигацию лица).</li>
                    </ul>
                </div>
            </section>
        );
    }
}