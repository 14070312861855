import * as React from "react";
import BenefitsGPS from "./BenefitsGPS/BenefitsGPS";
import "./FeaturesAndBenefitsOfGPS.scss";
import MonitoringCapabilities from "./MonitoringCapabilities/MonitoringCapabilities";

export default class FeaturesAndBenefitsOfGPS extends React.Component {
    public render() {
        return (
            <section className="features-and-benefits-of-gps">
                <MonitoringCapabilities />
                <BenefitsGPS />
            </section>
        );
    }
}