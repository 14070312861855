import * as React from 'react';
import './BenefitsOfMonitoringServices.scss';

export default class BenefitsOfMonitoringServices extends React.Component {
    public render() {
        return (
            <section className="benefits-of-monitoring-services">
                <div className="benefits-of-monitoring-services__wrap">
                    <h2 className="benefits-of-monitoring-services__title">Услуги</h2>
                    <p className="benefits-of-monitoring-services__text">Система мониторинга ВИСТАР-М позволяет 
                    Вам в любой момент времени видеть на карте местоположение человека, транспортного средства, 
                    груза или любого другого объекта, оборудованного ГЛОНАСС GPS трекером, GPS навигатором или
                    смартфоном с GPS/ГЛОНАСС-приёмником и специальным мобильным приложением.</p>
                    <ul className="benefits-of-monitoring-services__list">
                        <li className="benefits-of-monitoring-services__item">
                            <img className="benefits-of-monitoring-services__item-img" src="/img/benefits-of-monitoring-service/1.svg"/>
                            <p className="benefits-of-monitoring-services__item-text">
                            Мониторинг транспорта
                            </p>
                        </li>
                        <li className="benefits-of-monitoring-services__item">
                            <img className="benefits-of-monitoring-services__item-img" src="/img/benefits-of-monitoring-service/2.svg"/>
                            <p className="benefits-of-monitoring-services__item-text">
                            Мониторинг стационарных объектов
                            </p>
                        </li>
                        <li className="benefits-of-monitoring-services__item">
                            <img className="benefits-of-monitoring-services__item-img" src="/img/benefits-of-monitoring-service/3.svg"/>
                            <p className="benefits-of-monitoring-services__item-text">
                            Контроль расхода топлива
                            </p>
                        </li>
                        <li className="benefits-of-monitoring-services__item">
                            <img className="benefits-of-monitoring-services__item-img" src="/img/benefits-of-monitoring-service/4.svg"/>
                            <p className="benefits-of-monitoring-services__item-text">
                            Для личного использования
                            </p>
                        </li>
                    </ul>

                </div>
            </section>
        );
    }
}