import * as React from 'react';
import { NavLink as Link } from 'react-router-dom';
import './Header.scss';

export default class Header extends React.Component {
public render() {
    return (
        <header className="header">
            <Link to="/" className="header__logo" />
            <nav className="header__nav">
                <Link to="/vehicle-monitoring">Мониторинг транспорта</Link>
                <Link to="/about-company">О компании</Link>
                <Link to="/contacts">Контакты</Link>
            </nav>
        </header>
    );
} 
}