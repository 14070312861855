import * as React from 'react';
import BonientService from 'src/components/widgets/BonientService/BonientService';
import CertificatesAndPartners from 'src/components/widgets/CertificatesAndPartners/CertificatesAndPartners';
import Footer from 'src/components/widgets/Footer/Footer';
import HowToControlMonitoring from 'src/components/widgets/HowToControlMonitoring/HowToControlMonitoring';
import MonitoringSystemInstallationTime from 'src/components/widgets/MonitoringSystemInstallationTime/MonitoringSystemInstallationTime';
import SatelliteMonitoringOfTransport from 'src/components/widgets/SatelliteMonitoringOfTransport/SatelliteMonitoringOfTransport';
import Statement from 'src/components/widgets/Statement/Statement';
import WhatIsControlled from 'src/components/widgets/WhatIsControlled/WhatIsControlled';
import statementData from 'src/data/statementDate/statementDate';
import SliderPage from '../../widgets/SliderPage/SliderPage';
import './VehicleMonitoring.scss';

interface IVehicleMonitoringState {
    classColorDots: string;
}

export default class VehicleMonitoring extends React.PureComponent<any, IVehicleMonitoringState> {
    public render() {
        const statementProps = statementData.VehicleMonitoring;
        return (
            <div className={`main-page`}>
                <SliderPage>
                    <Statement {...statementProps}/>
                    <SatelliteMonitoringOfTransport />
                    <MonitoringSystemInstallationTime />
                    <HowToControlMonitoring />
                    <WhatIsControlled />
                    <BonientService />
                    <CertificatesAndPartners />
                    <Footer />
                </SliderPage>
            </div>
        );
    }
}