import * as React from 'react';
import './SliderPage.scss';
import {SliderPageContextType} from '../../contexts/SliderPageContext';

interface ISliderPageState {
    classColorDots: string;
    currentItem: number;
    currentOriginCoordY: number;
    coordsY: number[];
    sliderLength: number
}

interface ISliderPageProps {
    children: any
}

export const SliderPageScrollEvents = React.createContext<SliderPageContextType>({
    addScroll : function(): void {},
    removeScroll: function(): void {},
    addKeydown: function(): void {},
    removeKeydown: function(): void {}
});

export default class SliderPage extends React.PureComponent<ISliderPageProps, ISliderPageState> {
    public main: HTMLElement | null;
    public track: HTMLElement | null;
    constructor(props: ISliderPageProps) {
        super(props);
        this.state = {
            classColorDots: '',
            currentItem: 0,
            currentOriginCoordY: 0,
            coordsY: [],
            sliderLength: 0
        }
        this.onScrollChange = this.onScrollChange.bind(this);
        this.onPressArrow = this.onPressArrow.bind(this);
        this.onClickDots = this.onClickDots.bind(this);
        this.addScrollSliderPageEvents = this.addScrollSliderPageEvents.bind(this);
        this.removeScrollSliderPageEvents = this.removeScrollSliderPageEvents.bind(this);
        this.addKeydownSliderPageEvents = this.addKeydownSliderPageEvents.bind(this);
        this.removeKeydownSliderPageEvents = this.removeKeydownSliderPageEvents.bind(this);
    }

    public prev() {
        if (this.state.currentItem === 0 ) { return; }
        this.setState({currentItem: this.state.currentItem - 1});
    }

    public next() {
        if (this.state.currentItem === (this.state.sliderLength - 1)) { return; }
        this.setState({currentItem: this.state.currentItem + 1});
    }

    public addScrollSliderPageEvents() {
        window.addEventListener('wheel', this.onScrollChange);
    }

    public removeScrollSliderPageEvents() {
        window.removeEventListener('wheel', this.onScrollChange);
    }

    public addKeydownSliderPageEvents() {
        window.addEventListener('keydown', this.onPressArrow);
    }

    public removeKeydownSliderPageEvents() {
        window.removeEventListener('keydown', this.onPressArrow);
    }
    public componentDidMount() {
        const items = this.track!.childNodes as NodeListOf<HTMLElement>;
        const length = items!.length;
        const coordsY = [];
        for (let i = 0; i < length; i++) {
            coordsY.push(items[i].offsetTop);
        }
        this.setState({
            coordsY,
            sliderLength: length
        });
        
        this.addScrollSliderPageEvents();
        this.addKeydownSliderPageEvents();

        document.addEventListener('resize', () => {
            this.forceUpdate();
        })
    }

    public onScrollChange(e: WheelEvent) {
        const value = e.deltaY > 0 ?  'next' :  'prev'; 
        this[value]();
    };

    public onPressArrow(e: KeyboardEvent) {

        const key = e.key;
        if ( key === 'ArrowUp' || key === 'ArrowLeft' ) {
            this.prev();
        }
        if (key === 'ArrowDown' || key === 'ArrowRight' ) {
            this.next();
        }
    };
    public onClickDots(e: React.MouseEvent<HTMLButtonElement>) {
        const indexButton = +e.currentTarget.innerText;
        this.setState({currentItem: indexButton});
    }

    public renderDots() {
        const classDots = this.state.currentItem === 0 ? '' : 'dots--theme-purple';
        const activeElement = this.state.currentItem;
        return (

            <ul className={`dots ${classDots}`}>
                {
                this.state.coordsY.map((item, index) => {
                    if (index === activeElement) {
                        return (<li key={index} className="active">
                                    <button onClick={this.onClickDots}>{index}</button>
                                </li>);
                    }

                    return (<li key={index}>
                        <button onClick={this.onClickDots}>{index}</button>
                    </li>);
                }
                )}
            </ul>
        )
    }

    public render() {
        const currentCoordY = this.state.coordsY[this.state.currentItem];
        const style = {
            transform: `translateY(-${currentCoordY}px)`,
            transition: `transform 450ms ease` 
        }
        return (
            <SliderPageScrollEvents.Provider value={{
                addScroll: this.addScrollSliderPageEvents,
                removeScroll: this.removeScrollSliderPageEvents,
                addKeydown: this.addKeydownSliderPageEvents,
                removeKeydown: this.removeKeydownSliderPageEvents,
            }}> 
                <div className={`slider-page ${this.state.classColorDots}`} ref={main => this.main = main}>
                    <div className="slider-page__track" style={style} ref={track => this.track = track}>
                        {this.props.children}
                        {console.log(this.props.children)}
                    </div>
                    {this.renderDots()}
                </div>
            </SliderPageScrollEvents.Provider>
        );
    }
}