import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from './App';
import './fonts.scss';
import './index.scss';
import registerServiceWorker from './registerServiceWorker';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
);

registerServiceWorker();
