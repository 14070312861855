import * as React from "react";
import "./WhatIsControlled.scss";

export default class WhatIsControlled extends React.Component {
    public render() {
        return (
            <section className="what-is-controlled">
                <div className="what-is-controlled__wrap">
                    <h2 className="what-is-controlled__title">Что контролируем</h2>
                    <div className="what-is-controlled__info-text">
                        <p>
                            <span>8</span>Параметров
                        </p>
                        <p>
                            <span>5</span>Отчетов
                        </p>
                    </div>
                    <ul className="what-is-controlled__list">
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/1.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Маршрут</h3>
                            <p className="what-is-controlled__item-text">Соответствие заданному местонахождению и графику</p>
                        </li>
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/2.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Пассажиры и грузы</h3>
                            <p className="what-is-controlled__item-text">Высадка и посадка, текущее местоположение</p>
                        </li>
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/3.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Транспортное средство</h3>
                            <p className="what-is-controlled__item-text">Использование транспортного средства (условия, режим эксплуатации, качество вождения)</p>
                        </li>
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/4.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Движение автомобиля</h3>
                            <p className="what-is-controlled__item-text">Параметры движения автомобиля: скорость, пройденный путь, динамика разгона, торможения и др.</p>
                        </li>
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/5.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Техническое состояние</h3>
                            <p className="what-is-controlled__item-text">Техническое состояние, необходимость ТО</p>
                        </li>
                        <li className="what-is-controlled__item">
                            <span className="what-is-controlled__item-img-wrap">
                                <img className="what-is-controlled__item-img" src="/img/what-is-controlled/6.svg"/>
                            </span>
                            <h3 className="what-is-controlled__item-title">Расход топлива</h3>
                            <p className="what-is-controlled__item-text">Расход топлива общая, топливная эффективность, качество топлива</p>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}