import * as React from 'react';
import Slider from 'react-slick';
import './CertificatesAndPartners.scss';

export default class CertificatesAndPartners extends React.Component {
    public render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 400,
            slidesToShow: 5,
            slidesToScroll: 1
        };
        return(
            <section className="certificates-and-partners">
                <div className="certificates-and-partners__wrap">
                    <div className="certificates">
                        <h2 className="certificates__title title">Сертификаты</h2>
                        <img className="certificates__img" src="/img/certificates.jpg" alt=""/>
                        <ul className="certificates__list">
                            <li className="certificates__item">
                                <h3 className="certificates__item-title">Лицензия</h3>
                                <p className="certificates__item-text">На оказание услуг связи</p>
                            </li>
                            <li className="certificates__item">
                                <h3 className="certificates__item-title">Свидетельство</h3>
                                <p className="certificates__item-text">О членстве Ассоциации разработчиков Глонасс</p>
                            </li>
                            <li className="certificates__item">
                                <h3 className="certificates__item-title">Сертификат ISO</h3>
                                <p className="certificates__item-text">Международного образца</p>
                            </li>
                        </ul>
                    </div>
                    <div className="partners">
                        <h2 className="partners__title title">Клиенты и партнеры</h2>
                        <Slider {...settings}>
                            <div>
                                <img className="partners__img" src="/img/partners/1.jpg" alt=""/>
                            </div>
                            <div>
                                <img className="partners__img" src="/img/partners/2.jpg" alt=""/>
                            </div>
                            <div>
                                <img className="partners__img" src="/img/partners/3.jpg" alt=""/>
                            </div>
                            <div>
                                <img className="partners__img" src="/img/partners/4.jpg" alt=""/>
                            </div>
                            <div>
                                <img className="partners__img" src="/img/partners/5.jpg" alt=""/>
                            </div>
                            <div>
                                <img className="partners__img" src="/img/partners/5.jpg" alt=""/>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}