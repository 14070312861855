import * as React from 'react';
import Header from '../Header/Header';
import './Statement.scss';

interface IStatementProps {
    title: JSX.Element;
    text: string;
    classModifier: string | null;
}

export default class Statement extends React.Component<IStatementProps, any> {
    constructor(props: IStatementProps) {
        super(props);
    }
    public render() {
        const classModifier = `statement--${this.props.classModifier}`;
        const title = this.props.title ;
        const text = this.props.text;
        return (
            <section className={`statement ${classModifier}`}>
                <div className="statement__wrap">
                    <Header />
                    <div className="statement__content">
                        <h1 className="statement__title">{title}</h1>
                        <p className="statement__text">{text}</p>
                    </div>
                    <div className="statement__contacts-info">
                        <p className="statement__email">info@vistar.su</p>
                        <p className="statement__phone">8 951 551 70 00</p>
                        <button className="statement__bell">Заказать звонок</button>
                    </div>
                </div>
            </section>
        );
    }
} 